<template>
  <base-modal
    v-if="userProfile.integrations.gumroad.currency"
    @close="close"
    :loading="submitting || !products"
    title="Create a sale"
    dataCy="gumroad-sale-modal"
  >
    <div data-cy="sale-no-product" v-if="isNoProductOverlayDisplayed">
      <p>
        You have not created any Gumroad products yet. Please create one, refresh the page then try
        again.
      </p>
    </div>
    <div class="max-w-610 p-6" v-else>
      <div class="relative my-2 flex items-center gap-x-3 lg:mt-0">
        <new-base-radio
          v-for="gumroadSaleTypeInfo in gumroadSaleTypesInfo"
          :key="gumroadSaleTypeInfo.type"
          name="gumroadSaleTypes"
          :id="gumroadSaleTypeInfo.type"
          :value="gumroadSaleTypeInfo.type"
          v-model="gumroadSaleType"
          size="sm"
          type="button"
        >
          {{ gumroadSaleTypeInfo.name }}
        </new-base-radio>
      </div>

      <div role="form" class="needs-validation">
        <div class="space-y-3">
          <div class="grid grid-cols-2 items-center justify-center gap-y-3">
            <label class="text-dark-mode-15" for="product">Product</label>
            <base-select
              id="product"
              :options="products || []"
              labelAttribute="name"
              v-model="selectedProduct"
              placeholder="Select a Product"
              data-cy="product-select"
            />

            <label class="text-dark-mode-15" for="Discount">Discount code name</label>
            <base-text-input
              id="Discount"
              v-model="offerCode"
              placeholder="Discount code name"
              :error="offerCodeNameErrorMessage"
              data-cy="discount-code-name-input"
            />

            <label class="text-dark-mode-15" for="amount">{{ amountOffPlaceholder }}</label>
            <base-number-input
              id="amount"
              min="1"
              v-model="amountOff"
              :placeholder="amountOffPlaceholder"
              data-cy="amount-off-input"
            />

            <template v-if="gumroadSaleType === 'countdown'">
              <label class="text-dark-mode-15" for="target-sales">Target sales</label>
              <base-number-input
                id="target-sales"
                min="1"
                v-model="targetSales"
                placeholder="Target sales"
                data-cy="target-sales-input"
              />
            </template>

            <label class="text-dark-mode-15" for="sale-duration">Sale duration (hours)</label>
            <base-number-input
              id="sale-duration"
              min="1"
              v-model="saleDuration"
              placeholder="Sale duration (hours)"
              data-cy="sale-duration-input"
            />

            <label class="text-dark-mode-15" for="start-time">Start Time</label>

            <date-picker
              id="start-time"
              class="z-50"
              v-model="startsAt"
              data-cy="start-time-input"
              mode="dateTime"
              :min-date="minDateForInput"
              :max-date="maxDateForInput"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <base-text-input
                  :value="inputValue"
                  v-on="inputEvents"
                  :error="getTimeError(false, startsAt, 5)"
                >
                  <inline-svg
                    slot="icon"
                    src="/img/icons/date-icon.svg"
                    class="h-6 w-6 fill-current"
                  />
                </base-text-input>
              </template>
            </date-picker>
          </div>
          <template v-if="gumroadSaleType === 'timer'">
            <hr />

            <h1>Reminders</h1>

            <div class="space-y-3">
              <label class="text-dark-mode-15">Less than two hours left</label>
              <div class="flex flex-wrap gap-2">
                <new-base-checkbox
                  v-for="(name, val) in lessThanTwoHoursRepliesOptions"
                  :key="val"
                  :inputValue="val"
                  :label="name"
                  v-model="lessThanTwoHoursRepliesInMinutes"
                  type="tag"
                />
              </div>
            </div>

            <div class="relative z-0 grid grid-cols-2 items-center justify-center gap-y-3">
              <template v-if="saleDuration >= 3">
                <label class="text-dark-mode-15">Between 2 to 4 hours left</label>
                <div class="flex flex-wrap gap-2">
                  <new-base-radio
                    v-for="(name, val) in twoToFourHoursRepliesOptions"
                    :key="val"
                    name="twoToFourHoursRepliesOptions"
                    :value="parseInt(val)"
                    v-model="twoToFourHoursReplyDelayInHours"
                    size="sm"
                    type="tag"
                  >
                    {{ name }}
                  </new-base-radio>
                </div>
              </template>

              <template v-if="saleDuration >= 7">
                <label class="text-dark-mode-15">More than 5 hours left</label>
                <div class="flex flex-wrap gap-2">
                  <new-base-radio
                    v-for="(name, val) in fiveOrMoreHoursRepliesOptions"
                    :key="val"
                    name="fiveOrMoreHoursRepliesOptions"
                    :value="parseInt(val)"
                    v-model="fiveOrMoreHoursReplyDelayInHours"
                    size="sm"
                    type="tag"
                  >
                    {{ name }}
                  </new-base-radio>
                </div>
              </template>
            </div>
          </template>

          <div
            v-if="gumroadSaleType === 'countdown'"
            class="grid grid-cols-2 items-center justify-center gap-y-3"
          >
            <label class="text-dark-mode-15" for="delay">Delay between replies</label>
            <base-select
              id="delay"
              :options="delayOptions"
              labelAttribute="label"
              valueAttribute="value"
              v-model="replyDelay"
              placeholder="Select delay between replies"
              data-cy="delay-between-replies-select"
            />
          </div>
        </div>

        <div class="border my-6 overflow-hidden rounded-lg border-divider-darker p-4">
          <div class="mb-4 flex items-center gap-x-4">
            <a
              :href="'https://twitter.com/' + userProfile.username"
              target="_blank"
              class="flex-shrink-0"
            >
              <span class="sr-only">profile pic</span>
              <img
                :alt="userProfile.name + '\'s profile picture'"
                :src="userProfile.photoURL"
                class="avatar-img"
              />
            </a>
            <a
              :href="'https://twitter.com/' + userProfile.username"
              target="_blank"
              class="text-base font-bold"
            >
              {{ userProfile.name }}
            </a>
          </div>

          <div ref="tweet_container" class="relative">
            <textarea
              @focus="wasStatusFocused = true"
              rows="3"
              class="outline-none dark-mode-bg-80 w-full px-0 py-2 text-lg text-primary"
              v-model="status"
              dir="auto"
              ref="tweet"
              label="Status"
              placeholder="Type in the sale tweet..."
            ></textarea>
            <span class="absolute bottom-0 right-0">
              <character-counter
                :maximumCharacters="tweetCharactersLimit"
                :textLength="tweetLength"
              />
            </span>
          </div>

          <div class="link-preview container-fluid" v-if="linkPreview">
            <hr class="my-4" />

            <div class="flex items-center">
              <div class="mr-4">
                <img
                  class="rounded h-12 w-12 overflow-hidden object-cover shadow-xl"
                  :src="linkPreview.image"
                  alt="product image"
                />
              </div>
              <div class="flex-grow-1">
                <div class="font-weight-medium text-sm">
                  {{ linkPreview.title }}
                </div>
                <div class="text-xs">gumroad.com</div>
              </div>
            </div>
          </div>
        </div>

        <div class="space-y-3">
          <div class="flex items-center justify-between">
            <div class="flex items-center font-weight-medium">
              Auto delete tweets after sale has ended
              <info-tooltip content="Auto delete tweets after sale has ended" />
            </div>

            <base-toggle
              name="auto-delete-tweets-switch"
              id="auto-delete-tweets-switch"
              v-model="autoDeleteTweets"
              data-cy="auto-delete-tweets-switch"
            />
          </div>

          <div v-if="gumroadSaleType === 'countdown'" class="flex items-center justify-between">
            <div class="flex items-center font-weight-medium">
              Enable quote tweets reminders
              <info-tooltip content="Enable 'N copies left!' quote tweet reminders" />
            </div>

            <base-toggle
              data-cy="quotes-tweets-reminders-switch"
              name="quotes-tweets-reminders-switch"
              id="quotes-tweets-reminders-switch"
              v-model="enableQuoteTweets"
            />
          </div>

          <div v-if="gumroadSaleType === 'countdown'" class="flex items-center justify-between">
            <div class="flex items-center font-weight-medium">
              Customize Words
              <info-tooltip
                content="Add your voice to the automatic updates, or change them to a different language!"
              />
            </div>

            <base-toggle
              data-cy="customize-words-switch"
              name="customize-words-switch"
              id="customize-words-switch"
              v-model="showCustomizeTextBlock"
            />
          </div>

          <div
            class="mb-6 mt-3 grid grid-cols-2 items-center justify-center gap-y-2"
            v-if="gumroadSaleType === 'countdown' && customWording && showCustomizeTextBlock"
          >
            <label class="text-dark-mode-15" for="custom-wording-two-minutes">2 Minutes Left</label>
            <base-text-input
              id="custom-wording-two-minutes"
              v-model="customWording.twoMinutesLeft"
              placeholder="2 minutes left!"
            />

            <label class="text-dark-mode-15" for="custom-wording-five-minutes"
              >5 Minutes Left</label
            >
            <base-text-input
              id="custom-wording-five-minutes"
              v-model="customWording.fiveMinutesLeft"
              placeholder="5 minutes left!"
            />

            <label class="text-dark-mode-15" for="custom-wording-fifteen-minutes"
              >15 Minutes Left</label
            >
            <base-text-input
              id="custom-wording-fifteen-minutes"
              v-model="customWording.fifteenMinutesLeft"
              placeholder="15 minutes left!"
            />

            <label class="text-dark-mode-15" for="custom-wording-thirty-minutes"
              >30 Minutes Left</label
            >
            <base-text-input
              id="custom-wording-thirty-minutes"
              v-model="customWording.thirtyMinutesLeft"
              placeholder="30 minutes left!"
            />

            <label class="text-dark-mode-15" for="custom-wording-one-hour">1 Hour Left</label>
            <base-text-input
              id="custom-wording-one-hour"
              v-model="customWording.oneHourLeft"
              placeholder="1 hour left!"
            />

            <label class="text-dark-mode-15" for="sale-over">Sale Over</label>
            <base-text-input
              id="sale-over"
              v-model="customWording.saleOver"
              placeholder="Sale over!"
            />

            <label class="text-dark-mode-15" for="custom-wording-one-copy-left">1 Copy Left</label>
            <base-text-input
              id="custom-wording-one-copy-left"
              v-model="customWording.oneCopyLeft"
              placeholder="1 copy left!"
            />

            <div class="flex items-center">
              <label class="text-dark-mode-15">N Copies Left</label>
              <info-tooltip content="Shows when there is more than 1 copy left"></info-tooltip>
            </div>
            <div>
              <div class="flex items-center gap-x-2">
                <base-text-input data-cy="n-copies-left-input" v-model="customWording.nCopiesLeftBefore" placeholder="Before" />
                <div class="dark-mode-bg flex h-12 w-12 items-center rounded-lg px-3">n</div>
                <base-text-input
                  v-model="customWording.nCopiesLeftAfter"
                  placeholder="copies left!"
                />
              </div>
              <div class="mt-0.5 text-xs text-muted">
                Preview: {{ customWording.nCopiesLeftBefore || 2 }}
                {{ customWording.nCopiesLeftAfter }}
              </div>
            </div>
          </div>
        </div>

        <new-button
          class="ml-auto mt-6"
          data-cy="add-sale-button"
          @click="saveSale"
          :disabled="!canFormBeSubmitted()"
        >
          Add Sale
        </new-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
  import { adjustTextAreaHeight } from '../../util/adjustTextAreaHeight';
  import controller from '@/controller';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import dao from '@/dao';
  import { GumroadSale } from '../../models/GumroadSale';
  import lodash from 'lodash';
  import { mapGetters } from 'vuex';
  import moment from 'moment';
  import 'moment-timezone';
  import ThreadMixin from '../Mixins/ThreadMixin';
  import DatePicker from 'v-calendar/lib/components/date-picker.umd';
  import CharacterCounter from '../../components/CharacterCounter.vue';
  import TweetCharactersLimitMixin from '../Mixins/TweetCharactersLimitMixin.vue';

  const fb = require('../../firebase');
  const getSymbolFromCurrency = require('currency-symbol-map');

  export default {
    name: 'new-gumroad-sale-modal',
    async created() {
      this.setTime();
      this.products = await controller.gumroadProducts(this.currentUser, this.userProfile.uid);
      if (this.products.length === 0) {
        this.isNoProductOverlayDisplayed = true;
      } else {
        this.selectedProduct = this.products[0];
      }

      this.customWording = lodash.get(this.userProfile, 'integrations.gumroad.customWording', GumroadSale.DEFAULT_GUMROAD_WORDING);
      this.onModalVisible();
    },
    components: {
      DatePicker,
      CharacterCounter,
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      amountOffPlaceholder() {
        const currency = this.selectedProduct.currency?.toUpperCase() || '';
        return `Amount off (in ${currency})`;
      },
      charactersLeftClass() {
        if (countTweetLength(this.status) >= 256) return 'character-count-exceeded';
        if (countTweetLength(this.status) > 236) return 'character-count-warning';
        return 'bg-light';
      },
      charactersLeftCounter() {
        return this.tweetCharactersLimit - countTweetLength(this.status);
      },
      maxDateForInput() {
        return moment.tz(this.timezone).add(5, 'month').format('YYYY-MM-DDTHH:mm');
      },
      minDateForInput() {
        return moment.tz(this.userProfile.timezone).add(5, 'minute').startOf('minute').format('YYYY-MM-DDTHH:mm:ss');
      },
      isOfferCodeUsedInAScheduledSale() {
        const nonStartedSales = this.allGumroadSales
          .filter((sale) => {
            const startsAt = moment.tz(sale.startsAt.seconds * 1000, this.userProfile.timezone);
            return !sale.hasEnded && startsAt.diff(moment.tz(this.userProfile.timezone)) > 0;
          })
          .map((sale) => sale.offerCode);
        return nonStartedSales.includes(this.offerCode);
      },
      linkToProduct() {
        return !this.selectedProduct ? '' : `${this.selectedProduct.short_url}/${this.offerCode}`;
      },
      tweetLength() {
        return countTweetLength(this.status);
      },
    },
    data() {
      return this.initialState();
    },
    methods: {
      canFormBeSubmitted() {
        // Time-based Gumroad sales are limited to 24 hours while countdown-based ones are limited to 7 days (168 hours)
        const saleDurationLimit = this.gumroadSaleType === 'timer' ? 24 : 168;
        return (
          countTweetLength(this.status) > 0 &&
          countTweetLength(this.status) <= this.tweetCharactersLimit - 24 &&
          this.isTimeValid(this.startsAt, 5) &&
          this.selectedProduct &&
          this.saleDuration > 0 &&
          (this.saleDuration <= saleDurationLimit) &&
          this.offerCode.length > 0 &&
          (this.gumroadSaleType === 'timer' || (this.targetSales > 0)) &&
          this.saleDuration > 0 &&
          this.amountOff > 0 &&
          !this.offerCodeAlreadyExists &&
          !this.offerCodeLoading &&
          !this.isOfferCodeUsedInAScheduledSale
        );
      },
      async checkIfOfferCodeAlreadyExists(offerCodeName) {
        this.offerCodeAlreadyExists = null;
        const maybeOfferCode = await controller.gumroadGetOfferCode(
          this.currentUser,
          this.userProfile.uid,
          this.selectedProduct.id,
          offerCodeName
        );
        this.offerCodeAlreadyExists = !lodash.isNil(maybeOfferCode);
        this.offerCodeLoading = false;
        return null;
      },
      initialState() {
        return {
          delayOptions: [
            {
              label: 'None',
              value: -1,
            },
            {
              label: '5 minutes',
              value: 5,
            },
            {
              label: '10 minutes',
              value: 10,
            },
            {
              label: '15 minutes',
              value: 15,
            },
            {
              label: '30 minutes',
              value: 30,
            },
            {
              label: '1 hour',
              value: 60,
            },
            {
              label: '2 hours',
              value: 120,
            },
          ],
          gumroadSaleTypesInfo: [
            { type: 'countdown', name: 'Count Based' },
            { type: 'timer', name: 'Time Based' },
          ],
          lessThanTwoHoursRepliesOptions: {
            1: '1m',
            2: '2m',
            5: '5m',
            15: '15m',
            30: '30m',
            45: '45m',
            60: '60m',
            90: '90m'
          },
          twoToFourHoursRepliesOptions: { 1: 'Every hour', 2: 'Every 2 hours' },
          fiveOrMoreHoursRepliesOptions: { 2: 'Every 2 hours', 4: 'Every 4 hours' },
          lessThanTwoHoursRepliesInMinutes: [ 1, 2, 5, 15, 30, 45, 60, 90 ],
          gumroadSaleType: 'countdown',
          twoToFourHoursReplyDelayInHours: 2,
          fiveOrMoreHoursReplyDelayInHours: 4,
          amountOff: 10,
          enableQuoteTweets: false,
          isNoProductOverlayDisplayed: false,
          offerCode: '',
          offerCodeAlreadyExists: null,
          offerCodeLoading: false,
          offerCodeNameErrorMessage: null,
          linkPreview: null,
          products: null,
          targetSales: 20,
          replyDelay: 5,
          selectedProduct: '',
          saleDuration: 16,
          startsAt: null,
          status: '',
          submitting: false,
          wasStatusFocused: false,
          showCustomizeTextBlock: false,
          customWording: null,
          autoDeleteTweets: true,
        };
      },
      saveSale() {
        if (!this.canFormBeSubmitted()) {
          this.$forceUpdate();
          return null;
        }

         this.$eventStore.gumroadSales('Create a sale: Add sale');

        const repliesDelays =
          this.gumroadSaleType === 'timer'
            ? {
                lessThanTwoHoursRepliesInMinutes: this.lessThanTwoHoursRepliesInMinutes.map((time) => parseInt(time)),
                twoToFourHoursReplyDelayInHours: parseInt(this.twoToFourHoursReplyDelayInHours),
                fiveOrMoreHoursReplyDelayInHours: parseInt(this.fiveOrMoreHoursReplyDelayInHours),
              }
            : null;

        const customWording = (() => {
          if (this.gumroadSaleType === 'timer') {
            return null;
          } else if (this.showCustomizeTextBlock === false) {
            return GumroadSale.DEFAULT_GUMROAD_WORDING;
          } else {
            return this.customWording;
          }
        })();

        const gumroadSale = GumroadSale.newGumroadSale(
          Number(this.amountOff),
          this.selectedProduct.id,
          this.selectedProduct.name,
          this.offerCode,
          Number(this.saleDuration),
          this.startsAt,
          this.status + ' ' + this.linkToProduct,
          this.gumroadSaleType === 'countdown' ? Number(this.targetSales) : null,
          fb.usersCollection.doc(this.userProfile.uid),
          this.gumroadSaleType === 'countdown' && this.replyDelay > 0 ? parseInt(this.replyDelay) : null,
          this.gumroadSaleType === 'countdown' ? this.enableQuoteTweets : false,
          customWording,
          this.gumroadSaleType,
          repliesDelays,
          this.autoDeleteTweets,
          this.userProfile.wasFirstGumroadSaleScheduled !== true && this.allGumroadSales.length === 0,
        );
        this.submitting = true;
        return gumroadSale.saveToFirestore(this.userProfile.timezone).then(() => {
          this.$eventStore.scheduleGumroadSale();
          this.selectedProduct = '';
          this.offerCode = '';
          this.close();
          this.submitting = false;
          return dao.gumroadSales.saveDefaultWording(this.userProfile.uid, this.customWording);
        });
      },
      setTime: function () {
        this.startsAt = moment.tz(this.userProfile.timezone).add(10, 'minute').startOf('minute').format('YYYY-MM-DDTHH:mm:ss');
      },
      updateStatusIfNecessary() {
        if (this.wasStatusFocused || !this.selectedProduct) return;

        const saleDuration = Number(this.saleDuration);

        this.status =
          `🚨 Sale 🚨\n\n` +
          `${this.selectedProduct.name}\n\n` +
          `${getSymbolFromCurrency(this.selectedProduct.currency)}${this.amountOff} off ${
            this.gumroadSaleType === 'countdown' ? `for ${this.targetSales} orders` : ''
          }\n\n` +
          `Expires in ${saleDuration} ${saleDuration === 1 ? 'hour' : 'hours'}`;
      },
      updateModalWithDuplicatedGumroadSaleData() {
        this.amountOff = this.duplicatedGumroadSale.amountOff / 100;
        this.targetSales = this.duplicatedGumroadSale.targetSales;
        this.enableQuoteTweets = this.duplicatedGumroadSale.enableQuoteTweets;
        this.replyDelay = this.duplicatedGumroadSale.replyDelay;
        this.saleDuration = this.duplicatedGumroadSale.saleDuration;
        this.gumroadSaleType = lodash.get(this.duplicatedGumroadSale, 'type', 'countdown');
        this.autoDeleteTweets = this.duplicatedGumroadSale.autoDeleteTweets;

        if (this.duplicatedGumroadSale.type === 'timer') {
          this.twoToFourHoursReplyDelayInHours = this.duplicatedGumroadSale.repliesDelays.twoToFourHoursReplyDelayInHours;
          this.fiveOrMoreHoursReplyDelayInHours = this.duplicatedGumroadSale.repliesDelays.fiveOrMoreHoursReplyDelayInHours;
          this.lessThanTwoHoursRepliesInMinutes = this.duplicatedGumroadSale.repliesDelays.lessThanTwoHoursRepliesInMinutes;
        } else if (!lodash.isEqual(this.duplicatedGumroadSale.customWording, GumroadSale.DEFAULT_GUMROAD_WORDING)) {
          this.showCustomizeTextBlock = true;
          this.customWording = this.duplicatedGumroadSale.customWording;
        } else {
          this.showCustomizeTextBlock = false;
        }

        this.$nextTick(() => {
          this.status = this.duplicatedGumroadSale.status;
        })
      },
      async updateTweetPreview(product) {
        if (!product) return;
        this.linkPreview = (await controller.previewLink(this.currentUser, product.short_url)).data;
      },
      onModalVisible() {
        if (!this.show || !this.products) return;

        if (this.duplicatedGumroadSale) {
          this.selectedProduct = this.products.find(
            (product) => product.id === this.duplicatedGumroadSale.gumroadProductId
          );
          this.updateModalWithDuplicatedGumroadSaleData();
        } else {
          this.selectedProduct = this.products[0];
        }

        this.setTime();
        this.updateStatusIfNecessary();
      },
    },
    props: {
      show: Boolean,
      allGumroadSales: Array,
      duplicatedGumroadSale: { type: Object, default: null },
    },
    watch: {
      amountOff: function () {
        this.updateStatusIfNecessary();
      },
      offerCode: lodash.debounce(function (val) {
        if (!val) return;
        this.offerCodeLoading = true;
        this.checkIfOfferCodeAlreadyExists(val).then(() => {
          this.offerCodeNameErrorMessage =
            this.isOfferCodeUsedInAScheduledSale === true
              ? 'Offer code already used for a scheduled sale.'
              : this.offerCodeAlreadyExists === true
              ? 'Offer code already exists. Please choose another one.'
              : '';
        });
      }, 400, { leading: true }),
      selectedProduct: function (val) {
        this.updateStatusIfNecessary();
        this.updateTweetPreview(val);
      },
      gumroadSaleType(val) {
        this.updateStatusIfNecessary();
        if (val === 'countdown') {
          this.$eventStore.gumroadSales('Create a sale: Count based');
        } else {
          this.$eventStore.gumroadSales('Create a sale: Time based');
        }
      },
      show: function () {
        this.onModalVisible();
      },
      targetSales: function () {
        this.updateStatusIfNecessary();
      },
      saleDuration: function (val) {
        if (this.gumroadSaleType === 'timer') {
          if (parseInt(val) === 1) {
            this.lessThanTwoHoursRepliesOptions = lodash.omit(this.lessThanTwoHoursRepliesOptions, [60, 90]);
            this.lessThanTwoHoursRepliesInMinutes = this.lessThanTwoHoursRepliesInMinutes.filter(time => time < 60);
          } else {
            this.lessThanTwoHoursRepliesOptions = { ...this.lessThanTwoHoursRepliesOptions, 60: '60m', 90: '90m'}
          }
        }

        this.updateStatusIfNecessary();
      },
    },
    mixins: [ThreadMixin, TweetCharactersLimitMixin],
    updated() {
      if (this.$refs.tweet && this.$refs.tweet_container) {
        adjustTextAreaHeight(this.$refs.tweet, this.$refs.tweet_container);
      }
    },
  };
</script>

<style>
  @supports(padding: max(0px)) {
    form {
      padding-bottom: env(safe-area-inset-bottom)!important;
    }
  }
  textarea {
    resize: none;
  }
</style>
